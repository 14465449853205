import { Link, useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import LogoImage from "./LogoImage";
import React from "react";

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles } from "@material-ui/core/styles";

const Header = ({ siteTitle }) => {
  const {
    site: {
      siteMetadata: { color },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            color
          }
        }
      }
    `
  );
  return (
    <header
      style={{
        background: `linear-gradient(0deg, #141E30, #243B55)`,
        position: "sticky",
        zIndex: 1000,
        top: 0,
        flexGrow: 0,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          height: "var(--headerHeight, 50px)",
          display: "flex",
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <div style={{ width: `2.5em`, marginRight: "1em" }}>
              <LogoImage style={{ transform: "scale(1.4)" }} />
            </div>
            <h1 style={{ margin: 0, fontSize: "2.25em" }}>
              <Link
                to="/"
                style={{
                  fontFamily:
                    '"SF Compact Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                {siteTitle}
              </Link>
            </h1>
          </Box>
          <Box display="flex" alignItems="center">
            <MobileDesktopMenu
              buttons={[
                { href: "/download", text: "Download" },
                // { href: "/learn", text: "Learn" },
                { href: "/blog", text: "Blog" },
                { href: "/contact", text: "Contact" },
              ]}
            />
          </Box>
        </Box>
      </div>
    </header>
  );
};

const useMenuStyles = makeStyles({
  list: {
    background: "linear-gradient(0deg, #141E30, #243B55)",
  }
})

const useMenuItemStyles = makeStyles({
  root: {
    "&:hover": {
      background: "rgba(255,255,255,0.1)"
    }
  }
})

function MobileDesktopMenu({
  buttons,
}: {
  buttons: {
    href: string;
    text: string;
    moreLeftMargin?: number;
    green?: boolean;
  }[];
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const menuClasses = useMenuStyles();
  const menuItemClasses = useMenuItemStyles();

  return (
    <>
      <Hidden smDown>
        {buttons.map(({ href, text, green = false }, i) => (
          <React.Fragment key={i}>
            {i !== 0 && (
              <Divider
                orientation="vertical"
                style={{ height: "1em", background: "white" }}
              />
            )}
            <h3
              style={{
                margin: "0px 11px",
                background: green ? "green" : undefined,
                padding: green ? "0.5em 1em" : undefined,
                borderRadius: green ? "1em" : undefined,
              }}
            >
              <Link
                to={href}
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                {text}
              </Link>
            </h3>
          </React.Fragment>
        ))}
      </Hidden>
      <Hidden mdUp>
        <div style={{ position: "relative", display: "inline-flex" }}>
          <Button
            aria-controls="mobile-menu"
            variant="outlined"
            ref={buttonRef}
            style={{
              padding: "5px",
              background: "none",
              outline: "none",
              border: "1px solid white",
              borderRadius: 2,
              fontSize: "1.5em",
              color: "white",
            }}
            onClick={() => setIsOpen((open) => !open)}
          >
            MENU
          </Button>
          <Menu
            id="mobile-menu"
            open={isOpen}
            anchorEl={() => buttonRef.current}
            keepMounted
            onClose={() => setIsOpen(false)}
            classes={menuClasses}
          >
            {buttons.map(({ href, text }, i) => (
              <div key={i} style={{ minWidth: 200 }}>
                <Link
                  to={href}
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  <MenuItem onClick={() => setIsOpen(false)} classes={menuItemClasses} button>{text}</MenuItem>
                </Link>
              </div>
            ))}
          </Menu>
        </div>
      </Hidden>
    </>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
