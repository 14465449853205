import React from "react";
import SEO from "../components/seo";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import facebook_white from "../images/facebook_white.svg";
import instagram_white from "../images/instagram_white.svg";
import twitter_white from "../images/twitter_white.svg";

import facebook_black from "../images/facebook_black.svg";
import instagram_black from "../images/instagram_black.svg";
import twitter_black from "../images/twitter_black.svg";

async function fetchCurrentLocationData() {
  const response = await fetch("https://json.geoiplookup.io/");
  return response.json();
}

export default function CallToAction({
  removeBackgroundColor,
}: {
  removeBackgroundColor?: boolean;
}) {
  const formRef = React.useRef();
  const [error, setError] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(formRef.current);
    let location_data = null;
    try {
      location_data = await fetchCurrentLocationData();
    } catch {}

    const email = data.get("email");

    const response = await fetch(
      process.env.NODE_ENV === "development"
        ? "http://localhost:8080/v1/graphql"
        : "/db/v1/graphql",
      {
        body: JSON.stringify({
          operationName: "InsertSubscribedEmails",
          query: `
          mutation InsertSubscribedEmails($object: subscribed_emails_insert_input!) {
            insert_subscribed_emails(objects: [$object]) {
              affected_rows
            }
          }`,
          variables: {
            object: {
              email,
              ...(location_data ? { location_data } : {}),
            },
          },
        }),
        method: "POST",
      }
    );

    try {
      const {
        data: {
          insert_subscribed_emails: { affected_rows },
        },
      } = await response.json();
      if (affected_rows === 1) {
        window.location.href = "/thank-you";
      }
    } catch {
      setError(true);
    }
  };

  const background = removeBackgroundColor
    ? "rgba(0,0,0,0.0)"
    : "linear-gradient(90deg, #141E30, #243B55)";
  const color = removeBackgroundColor ? "black" : "white";

  const facebook = removeBackgroundColor ? facebook_black : facebook_white;
  const instagram = removeBackgroundColor ? instagram_black : instagram_white;
  const twitter = removeBackgroundColor ? twitter_black : twitter_white;

  return (
    <div
      style={{
        background: background,
        color: color,
        flexGrow: 0,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <h3 style={{ marginTop: "1.45rem" }}>
        Interested in changing your business?
      </h3>

      <form onSubmit={handleSubmit} ref={formRef}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "20rem",
              backgroundColor: "white",
              borderRadius: "1rem",
              marginBottom: "1.45rem",
            }}
          >
            <TextField
              variant="filled"
              color="secondary"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="email"
              placeholder="What is your email address?"
              name="email"
              required
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ backgroundColor: "white", color: "black" }}
          >
            Subscribe
          </Button>
        </div>
      </form>

      <p style={{ marginTop: "1.45rem" }}>
        Subscribe to our newsletter or follow us!
      </p>
      <div>
        <OutboundLink href="https://twitter.com/PanoRoomApp">
          <img src={twitter} style={{ width: "48px", height: "48px" }} />
        </OutboundLink>

        <OutboundLink href="https://www.facebook.com/groups/175858406851243">
          <img src={facebook} style={{ width: "48px", height: "48px" }} />
        </OutboundLink>

        <OutboundLink href="https://www.instagram.com/PanoRoomApp/">
          <img src={instagram} style={{ width: "48px", height: "48px" }} />
        </OutboundLink>
      </div>
    </div>
  );
}
